import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import image from '../../logo.png'

const Navbar = () => {

  return (
    <AppBar>
      <Toolbar sx={{
        display: 'flex',
        justifyContent: 'center',
        backgroundColor: 'white'
      }}>
        <img style={{ filter: 'invert(1)'}} src={image}/>
      </Toolbar>
    </AppBar>
  );
}

export default Navbar;
