import React, {useState} from 'react';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import Link from '@mui/material/Link';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import slide1 from '../../images/slide1.png'
import slide2 from '../../images/slide2.png'
import slide3 from '../../images/slide3.png'
import Navbar from '../general/Navbar';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';

const Home = () => {

  return (
    <Box sx={{
      display: 'flex',
      paddingTop: '100px'
    }} id="Tables">
      <Drawer sx={{
        width: '150px',
        '& .MuiDrawer-paper': {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }
        }} variant='permanent' anchor='left'>
        <List>
          <Items/>
        </List>
      </Drawer>
      <FeatureList/>

    </Box>
  )
}

const CarouselBar = () => {
  return (
    <>
      <Typography id='Pictures' mb="2rem" color="black" variant="h4">
        📷 Photos Of Tables In Use
      </Typography>
      <Carousel>
         <div>
             <img src={slide1} />
             <p className="legend">Generating suggestions for
             package imports based on previous code</p>
         </div>
         <div>
             <img src={slide2} />
             <p className="legend">Notifying you of best
             practices for Security, Code, and Algorithms</p>
         </div>
         <div>
             <img src={slide3} />
             <p className="legend">Tables providing a overall
             score of your code based on our expert benchmarks.</p>
         </div>
       </Carousel>
     </>
   )
}

const Items = () => {
  const list_items = ['Tables', 'Features', 'Reading',
  'Pictures', 'Errors'];
  return (list_items.map(x => {
    const a = `#${x}`
    return (
      <ListItem>
        <ListItemButton>
          <Link sx={{color: 'black'}} href={`#${x}`} underline="none">
            💡{x}
          </Link>
        </ListItemButton>
      </ListItem>
    )
  }))
}

const EmailCollector = () => {
    const [final, setFinal] = useState(null);

    return (
    <>
      <Typography id='Erros' mb="2rem" color="black" variant="h4">
        Sign Up To Our Newsletter and Receive Updates
      </Typography>

      <Box
        sx ={{
          display:'flex',
          flexDirection: 'column',
          alignItems: 'center',
          mb: '2rem'
      }}>
        <Box display="inline-block"
        sx={{
          width: '20rem',
          mb: '2rem'}}
        >
          <TextField
            fullWidth
            id="name-required"
            label="Name"
            placeholder="Jon Doe"
          />
        </Box>
        <Box display="inline-block"
        sx={{
          width: '20rem',
          mb: '2rem'}}
        >
          <TextField
            fullWidth
            id="email-required"
            label="Email"
            placeholder="example@gmail.com"
          />
        </Box>
        <Box display="inline-block">
          <Button
          onClick={() => {setFinal(
            <Typography id='Errors' my="1rem" color="rgba(14, 228, 48, 0.53)" variant="h8">
              If the entered information is correct, please check your
              inbox for further instructions!
            </Typography>
          )}}
          variant="contained">Sign Up</Button>
        </Box>
        {final}
      </Box>
    </>
    )
}

const FeatureList = () => {
  const text_array = [
    'Powered by the Matsuda engine ',
    'Follows npm and Yarn best practices regarding autoinstall',
    'User-friendly messages',
    'Optional install',
    'Visual Code, Atom, Eclipse support.',
    'Like husky 4, supports',
    'macOS, Linux and Windows compatible',
    'Git GUIs',
    'Custom directories',
    'Monorepos',
    'Catches semantic errors',
    'Catches logical errors',
    'Catches security pitfalls',
    'and more...'
  ];
  return (
    <Box id="Features" sx={{width: '100%'}}>
      <Box sx={{
        display: 'flex',
        flexDirection: "column"
      }}>

        <Box sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: 'center',
          flexDirection: "column"
        }}>
          <Typography mb="1rem"color="black" variant="h4">
            Tables is an IDE code and suggestion generator 😀
          </Typography>

          <Typography color="black" variant="h5">
            It has zero dependencies and is lightweight (6 kB)
          </Typography>

        </Box>

        <Box sx={{
            py: '3rem',
            px: '2rem'
        }}>
          <Box sx={{
            display: "flex",
            justifyContent: "flex-start",
            flexDirection: "column",
          }}>

          <Typography mb="2rem" color="black" variant="h4">
            📖 Features
          </Typography>
            {text_array.map(x => {
              return (<Typography
                sx={{borderLeft: '3px solid rgb(78, 245, 51)',
                paddingLeft: '1rem'}}
                mb="1.5rem"color="black" variant="h5">
                {x}
              </Typography>)
            })}
          </Box>
          <Box id='Reading'/>
          <Typography  mb="2rem" color="black" variant="h4">
            📖 Reading Best Practices From Repos
          </Typography>

          <Typography  mb="2rem" color="black" variant="h5">
            Tables AI model learns and matches your coding standards and best practices.
          </Typography>
          <Typography mb="2rem" color="black" variant="h5">
            Connect your own repositories (GitHub/Azure/Bitbucket) <br/>
            Tables AI trains your own model based on your specific code patterns
            <br/><br/>Get tailored code completions
          </Typography>

          <Typography mb="2rem" color="black" variant="h4">
            🐞 Catches Logical and Security Bugs
          </Typography>

          <Typography mb="2rem" color="black" variant="h5">
            Tables is powered by industry-leading security
            intelligence research, so you can find <br/> and fix vulnerabilities
            as soon as they’re discovered.
          </Typography>

          <CarouselBar/>
          <EmailCollector/>

          <Typography id='Errors' mb="10rem" color="rgb(150, 150, 150)" variant="h6">
            Tables AI is currently under development so if you're excited
            please find us and let us know on Social Media!
          </Typography>

        </Box>
    </Box>
  </Box>
  )
}


// 'angular/angular'
// 'angular/angular-cli'
// 'angular/components'
// 'vercel/hyper'
//
// NativeScript/NativeScript
// formatjs/formatjs
// react-bootstrap/react-bootstrap
// react-dnd/react-dnd
// react-grid-layout/react-grid-layout
// snabbdom/snabbdom
// logaretm/vee-validate
// zenorocha/clipboard.js
// NodeBB/NodeBB
// ant-design/ant-design

export default Home;
